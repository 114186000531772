.user_wrapp {
  border: 1 px solid black;
  width: 100%;
  height: 100vh;
}
.bgcolor {
  background-color: 'none' !important;
}
.set_add_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.set_varify_div {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* border: 1px solid black; */
  border-radius: 8px;
  margin: 100px auto;
  max-width: 500px;
  min-width: 250px;
  height: 320px;
  padding: 20px;
  position: relative;
}
.set_header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.sub_btn {
  position: absolute;
  bottom: -100px;
  right: 0px;
}
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

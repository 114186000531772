.heading {
  margin-bottom: 20px;
}
.main_div {
  height: auto;
  background-color: #ffffff;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
  padding: 2rem;
}
.set_img {
  padding: 20px;
}
.set_img .icon {
  background-image: linear-gradient(120deg, #c7c3b3, #b19494);
  color: #382929;
  padding: 15px;
  font-size: 100px;
  border-radius: 1rem;
}
.link_wrapp {
  margin-top: 40px;
}
.link_wrapp .link {
  margin-right: 15px;
  border-bottom: 2px solid black;
  border-width: 0;
  font-size: 18px;
  color: #382929;
  /* transition: all .5s; */
}
.link_wrapp .link:hover {
  border-bottom: 2px solid black;
  border-width: 100%;
}
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
